<template>

<div class="foot" v-html="content.foot" />

</template>

<script>

export default {

	computed: {

		content: function() {

			return this.$store.getters['data'].recommendations

		}

	}

}

</script>

<style scoped>

.foot {
	width: 100%;
	max-width: 1020px;
	margin: 0px auto;
	padding: 40px 0px;
}

.foot >>> p {
	line-height: 18px;
	text-align: center;
	font-size: 14px;
}

.foot >>> a {
	color: #5548AF;
}

.foot >>> a:hover {
	text-decoration: underline;
}

</style>
